.logo{
    color: #fff;
    margin-top: 44px;
    margin-bottom: 28px;
    font-size: 48px;
}

.logo-text{
    background:linear-gradient(#e18700, #ffd43a);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}