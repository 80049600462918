.admin-header {
    width:100%;
    max-width: 720px;
    margin-top: 14px;
    padding: 0 14px;
    
}

.nav-header {
    width:100%;
    background-color: #fff;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 4px;
}

.nav-header button {
    border: 0;
    background-color: transparent;
    margin-right: 24px;
    margin-left: 8px;
}

.nav-header a{
    margin-right: 14px;
    color: #21242d;
    transition: color 0.2s;
}

.nav-header a:hover{
    color: #eda617;
}