.login-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
}

.form{
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 600px;

}

.form button{
    height: 36px;
    border-radius: 4px;
    background-color: #3366ff;
    color: #fff;
    font-size: 18px;
}