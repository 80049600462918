.home-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 14px 0;
    align-items: center;
    justify-content: center;
}

.home-container h1{
    margin-bottom: 14px;
    color: #fff;
    font-size: 44px;
    padding-top: 94px;
    color: #fff;
    
}

.home-container span{
    color: #f1f1f1;
    margin-bottom: 18px;

}

.links {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 90%;
    text-align: center;


}

.link-area {
    background: #fff;
    width: 100%;
    margin-bottom: 18px;
    padding: 8px 0;
    user-select: none;
    border-radius: 4px;
    transition: transform 0.4s;
}

.link-area:hover{
    transform: scale(1.04);
}

.link-text {
    font-size: 18px;
    line-height: 150%;
    color: #000;

}

footer {
    display: flex;
    justify-content: center;
    gap: 14px;
    margin: 18px 0;
}

@media screen and (max-width: 550px){
    .home-container h1{
        font-size: 28px;
        text-align: center;
    }
    .link-text {
        font-size: 16px;
    }
}
@media screen and (max-width: 320px){
    .home-container h1{
        font-size: 24px;
        text-align: center;
    }
    .link-text {
        font-size: 14px;
    }
}