.admin-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    padding: 0 6px 24px 6px;
}

.label{
    color: #fff;
    font-weight: medium;
    margin-top: 8px;
    margin-bottom: 14px;

}

.container-colors{
    display: flex;
    margin: 14px 0;
    gap: 22px;
}

.right {
    margin-right: 14px;
}

.btn-register{
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
}

.btn-register svg{
    margin-left: 8px;
}

.title {
    color: #fff;
    margin: 28px 0 14px 0;
}

.list{
    width: 90%;
    max-width: 600px;
    display: flex;
    color: #fff;
    background-color: rgba(36,36,36,0.35);
    align-items: center;
    border-radius: 4px;
    padding: 14px 8px;
    justify-content: space-between;
    margin-bottom: 14px;
}

.preview {
    margin: 0 0 28px 0;
    padding: 4px;
    border: 1.5px solid rgba(255,255,255,0.5);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content:center;
    flex-direction: column;
}

.btn-delete {
    border: 1.5px dashed #fff;
    color: #fff;
    background-color: #000;
    padding: 4px 8px;
    border-radius: 2px;
}

.animate-pop{
    animation-duration: 0.5s;
    animation-name: animate-pop;
}

@keyframes animate-pop {
    0%{
        opacity: 0;
        transform:scale(0.7,0.7);
    }
    100% {
        opacity: 1;
        transform:scale(1,1);
    }
}